import { AuthProfileOutput } from '@grandstand/presentation-models/types/responses/profile'
import jwt from 'jsonwebtoken'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { authenticatedApiFetch } from '../apiClient/apiClient'
import { ConfigServiceContext } from '../services/config/ConfigService'
import { ItemStorage } from '../utils/storageUtils'
import { useLocalStorage } from './useLocalStorage'

export type CurrentUser = AuthProfileOutput | undefined

export type SetCurrentUser = (value: CurrentUser | ((val: CurrentUser) => CurrentUser)) => void

export const currentUserStorage = new ItemStorage<CurrentUser>('userService.currentUser', undefined)

export type RefreshCurrentUser = (forceRefresh?: boolean) => void

export const isValidUser = (user: CurrentUser): boolean => {
  if (user === undefined) {
    return true // it _is_ valid for our purposes
  }
  if (user.profile === undefined) {
    return false
  }
  if (user.profile.region === undefined) {
    return false
  }

  return true
}

export const getIsDTC = (currentUser: CurrentUser = currentUserStorage.getItem()): boolean => {
  const entitlements = currentUser?.profile.entitlements
  return entitlements !== undefined && entitlements.some((entitlement) => entitlement.type === 'dtc')
}

export const getIsLoggedIn = (user: CurrentUser = currentUserStorage.getItem()): boolean => {
  return user?.profile.internal?.email !== undefined
}

export const useValidUser = (): readonly [CurrentUser, SetCurrentUser, RefreshCurrentUser] => {
  const { currentConfig } = useContext(ConfigServiceContext)
  const [currentUser, setCurrentUser] = useLocalStorage<CurrentUser>(currentUserStorage.key, undefined)
  // states/refs for refreshProfile, refreshCurrentUser
  const [shouldRefresh, setShouldRefresh] = useState(true)
  const nextRefreshRef = useRef<number>(0)
  const isRefreshingRef = useRef(false)

  // Refresh user when the user token expires.
  useEffect(() => {
    if (currentUser?.user_token === undefined || currentConfig === undefined) {
      return
    }
    const { exp } = jwt.decode(currentUser.user_token) as { exp: number } // gross
    const refreshTime = exp * 1000 - Date.now() - 60 // subtract 60 seconds

    const refresh = async () => {
      if (currentUser?.user_token === undefined || currentConfig === undefined) {
        return
      }
      const response = await fetch(currentConfig?.API.services.auth_services.refresh, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser.user_token}`
        },
        body: JSON.stringify({ refresh_token: currentUser.refresh_token })
      })
      const unauthedUser = await response.json()
      setCurrentUser(unauthedUser)
    }

    // do the refresh in refreshTime
    const timeout = setTimeout(async () => {
      refresh()
    }, Math.max(refreshTime, 0))

    return () => {
      clearTimeout(timeout)
    }
  }, [currentConfig, currentUser, setCurrentUser])

  // Ensure the user is valid
  useEffect(() => {
    // If the user isn't valid, we clear it out, and it will be replaced by an unauthenticated user
    if (!isValidUser(currentUser)) {
      setCurrentUser(undefined)
    }
  }, [currentUser, setCurrentUser])

  // Get the unauthenticated user if we don't have a user
  useEffect(() => {
    const getUnauthenticatedUser = async () => {
      // If the current user exists, do nothing
      if (currentUser !== undefined && currentUser.user_token) {
        return
      }
      // The config hasn't been fetched yet
      if (currentConfig === undefined) {
        return
      }

      if (!currentConfig?.API?.services?.auth_services?.ballys?.anonymous) {
        return
      }

      // Otherwise get the unauthenticated user
      const response = await fetch(currentConfig.API.services.auth_services.ballys.anonymous, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ location_type: 'ip' })
      })

      const unauthedUser = await response.json()
      setCurrentUser(unauthedUser)
    }
    getUnauthenticatedUser()
  }, [currentConfig, currentUser, setCurrentUser])

  // Refreshes the user profile
  const refreshProfile = useCallback(async () => {
    try {
      const token = currentUser?.user_token
      const url = currentConfig?.API.services.auth_services.ballys.profile
      if (isRefreshingRef.current || !shouldRefresh || typeof token !== 'string' || typeof url !== 'string') {
        return
      }
      // Set refreshing to true
      isRefreshingRef.current = true

      // Get the unauthenticated user
      const response = await authenticatedApiFetch({
        url,
        token,
        method: 'GET'
      })

      // If the response is bad, do nothing
      if (!response.ok) {
        const error = await response.json()
        console.error('Error (bad response) @ useValidUser > refreshProfile', error)
      } else {
        const data = await response.json()
        setShouldRefresh(false)
        setCurrentUser(data)
      }
    } catch (error) {
      console.error('Error caught @ useValidUser > refreshProfile', error)
    } finally {
      // update nextRefreshRef time for next refresh
      nextRefreshRef.current = new Date().getTime() + 2 * 60000 // allow refresh in X minutes (X * 60000)
      isRefreshingRef.current = false
    }
  }, [currentConfig?.API.services.auth_services.ballys.profile, currentUser?.user_token, setCurrentUser, shouldRefresh])

  // allow client to refresh the user (forcibly or after a delay if forceRefresh !== true)
  const refreshCurrentUser: RefreshCurrentUser = (forceRefresh?: boolean) => {
    // scrap for now
  }

  return [currentUser, setCurrentUser, refreshCurrentUser]
}
