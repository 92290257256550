import { datadogRum } from '@datadog/browser-rum'
import { useContext } from 'react'
import { AppName, clientRuntimeConfig } from '../config'
import { ConfigServiceContext } from '../services/config/ConfigService'
import { Logger } from '../utils/logger'

type DDRumProps = {
  service: AppName
}

const DDRum = ({ service }: DDRumProps) => {
  const { currentConfig } = useContext(ConfigServiceContext)
  if (!currentConfig) {
    return null
  }

  const sessionSampleRate = currentConfig.API?.features?.dd_rum_config?.session_sample_rate || 0
  const sessionReplaySampleRate = currentConfig.API?.features?.dd_rum_config?.session_replay_sample_rate || 0

  if (sessionSampleRate === 0) {
    return null
  }
  try {
    datadogRum.init({
      applicationId: clientRuntimeConfig.dataDogRumApplicationId,
      clientToken: clientRuntimeConfig.dataDogRumClientToken,
      site: 'datadoghq.com',
      service,
      env: String(clientRuntimeConfig.environment),
      sessionSampleRate,
      sessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    })
  } catch (error) {
    Logger.of('DDRum').error('DD init error', {
      error
    })
  }
  return null
}

export default DDRum
