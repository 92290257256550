import CaptivateAnalytics from '@/components/CaptivateAnalytics'
import { TopNavProvider, useTopNavStore } from '@/components/TopNav/TopNavContext'
import { TopNavPortals } from '@/components/TopNav/TopNavPortals'
import { ModalProvider } from '@/contexts/ModalContext'
import ToastContextProvider from '@/contexts/ToastContext'
import useMediaQuery from '@/hooks/useMediaQuery'
import '@/styles/globals.css'
import { datadogLogs } from '@datadog/browser-logs'
import DDRum from '@grandstand-web/bally-web-shared/src/components/DDRum'
import { clientRuntimeConfig } from '@grandstand-web/bally-web-shared/src/config'
import { useLocalStorage } from '@grandstand-web/bally-web-shared/src/hooks/useLocalStorage'
import { ServiceProvider } from '@grandstand-web/bally-web-shared/src/services/ServiceProvider'
import { GlobalStateContextWrapper } from '@grandstand-web/bally-web-shared/src/services/config/GlobalStateService'
import type { SelectedPkg } from '@grandstand-web/bally-web-shared/src/type/SelectedPkg'
import { ActiveSubscriptionsResponse } from '@grandstand/presentation-models/types/responses/subscriptions'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useEffect, useState } from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import Footer from '../components/Footer'
import GlobalStyles from '../styles/components/GlobalStyles'

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
`
export type { SelectedPkg }
export type PkgSelectCtx = {
  selectedPkg: SelectedPkg | undefined
  setSelectedPkg: Dispatch<SetStateAction<SelectedPkg | undefined>> | undefined
  userIsSubscribedToSelectedPackage:
    | ((selectedPkg: SelectedPkg | undefined, allSubs: ActiveSubscriptionsResponse) => boolean)
    | undefined
}

export const PkgSelectionContext = createContext<PkgSelectCtx>({
  selectedPkg: undefined,
  setSelectedPkg: undefined,
  userIsSubscribedToSelectedPackage: undefined,
})

export type MultiStageAuthCtx = {
  verifiedCode?: string
  partnerSource?: string
  setVerifiedCode?: Dispatch<SetStateAction<string | undefined>>
  setPartnerSource?: Dispatch<SetStateAction<string | undefined>>
}

export const MultiStageAuthContext = createContext<MultiStageAuthCtx>({})

// For modal system: required by `react-modal`
Modal.setAppElement('#__next')

const ContentWrapper = styled.div<{ safeAreaTop: number }>`
  flex-grow: 1;
  padding-top: ${({ safeAreaTop }) => safeAreaTop}px;
`

const WithProviders = ({ children, applyPadding }: PropsWithChildren<{ applyPadding: boolean }>) => {
  const { safeAreaTop } = useTopNavStore()
  return <ContentWrapper safeAreaTop={applyPadding ? safeAreaTop : 0}>{children}</ContentWrapper>
}

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const isSettingsPage = router.pathname.startsWith('/settings')
  const applyPadding = !isSettingsPage
  const darkModeSelected = useMediaQuery('(prefers-color-scheme: dark)')
  const [selectedPkg, setSelectedPkg] = useLocalStorage<SelectedPkg | undefined>('selected-pkg', undefined)
  const [verifiedCode, setVerifiedCode] = useState<string>()
  const [partnerSource, setPartnerSource] = useState<string>()
  const isEmbedded = router.pathname.includes('_embed')

  try {
    datadogLogs.init({
      clientToken: clientRuntimeConfig.dataDogClientToken,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      sessionSampleRate: 100,
      service: 'web',
      env: String(clientRuntimeConfig.environment),
    })
  } catch (err) {
    console.log(err)
  }

  useEffect(() => {
    if (darkModeSelected) {
      document.documentElement.setAttribute('data-theme', 'dark')
    } else {
      document.documentElement.setAttribute('data-theme', 'light')
    }
  }, [darkModeSelected])

  // Selecting a package removes the "Connect to TV Provider" option from signin views.
  // Since this value is stored indefinitely in local storage, users can be permanently stuck in this state.
  // The purpose of this effect is to clear the selected package when a user "exits the purchase flow".
  // See: WW-1376
  useEffect(() => {
    if (!/^\/(packages|signin|checkout)/.test(router.asPath)) {
      setSelectedPkg(undefined)
    }
  }, [setSelectedPkg, router.asPath])

  const userIsSubscribedToSelectedPackage = (
    selectedPkg: SelectedPkg | undefined,
    allSubs: ActiveSubscriptionsResponse
  ) => {
    if (!selectedPkg || !allSubs || allSubs.length <= 0) {
      return false
    }
    return allSubs
      .filter((sub) => sub.subscription_status !== 'expired')
      .map((sub) => sub.service_id)
      .includes(selectedPkg.service_id)
  }

  return (
    <>
      <Head>
        <title>Bally Sports</title>
        <meta name="viewport" content="width=device-width" />
        <link rel="icon" href="favicon.png" />
      </Head>

      <Script
        async={true}
        crossOrigin="anonymous"
        type="text/javascript"
        src="//consent.trustarc.com/notice?domain=sbgi.net&c=teconsent&text=true&js=nj&noticeType=bb&privacypolicylink=http%3A%2F%2Fsbgi.net%2Fprivacy-policy&irmc=irmlink"
      ></Script>
      <ServiceProvider
        nativeAppVersion={clientRuntimeConfig.rootVersion}
        gtmId={clientRuntimeConfig.gtmId}
        appVersion={clientRuntimeConfig.rootVersion}
        apiEnvironment={String(clientRuntimeConfig.environment)}
        app="web"
      >
        <DDRum service="web" />
        <CaptivateAnalytics />
        <GlobalStyles />
        <GlobalStateContextWrapper>
          <PkgSelectionContext.Provider value={{ selectedPkg, setSelectedPkg, userIsSubscribedToSelectedPackage }}>
            <MultiStageAuthContext.Provider value={{ verifiedCode, partnerSource, setPartnerSource, setVerifiedCode }}>
              <TopNavProvider>
                <ToastContextProvider>
                  <ModalProvider>
                    <PageWrapper>
                      {/* Don't show the Top Nav if embedded */ !isEmbedded && <TopNavPortals />}
                      <WithProviders applyPadding={applyPadding}>
                        <Component {...pageProps} />
                      </WithProviders>
                      {/* Don't show the footer if embedded */ !isEmbedded && <Footer />}
                    </PageWrapper>
                  </ModalProvider>
                </ToastContextProvider>
              </TopNavProvider>
            </MultiStageAuthContext.Provider>
          </PkgSelectionContext.Provider>
        </GlobalStateContextWrapper>
      </ServiceProvider>
    </>
  )
}
