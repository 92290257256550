import React from 'react'
import { AnalyticServiceProvider } from './analytics/AnalyticService'
import { ConfigServiceProvider } from './config/ConfigService'
import { TealiumServiceProvider } from './tealium/TealiumService'
import { UserServiceProvider } from './user/UserService'

type ServiceProviderProps = {
  gtmId: string
  appVersion: string
  nativeAppVersion: string
  apiEnvironment: string
  app: 'web' | 'connected-web'
}

export const ServiceProvider = ({
  children,
  gtmId,
  appVersion,
  nativeAppVersion,
  apiEnvironment,
  app
}: React.PropsWithChildren<ServiceProviderProps>) => {
  return (
    <ConfigServiceProvider
      nativeAppVersion={nativeAppVersion}
      apiPlatform={app === 'connected-web' ? 'cw' : 'web'}
      appVersion={appVersion}
      apiEnvironment={apiEnvironment}
    >
      <UserServiceProvider app={app}>
        <TealiumServiceProvider app={app}>
          <AnalyticServiceProvider app={app} gtmId={gtmId}>
            {children}
          </AnalyticServiceProvider>
        </TealiumServiceProvider>
      </UserServiceProvider>
    </ConfigServiceProvider>
  )
}
