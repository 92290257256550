import React, { useState } from 'react'

type GlobalStateContextType = {
  isPurchasingDisabled: boolean
  actions: {
    setIsPurchasingDisabled: React.Dispatch<React.SetStateAction<boolean>>
  }
}

export const GlobalStateContext = React.createContext<GlobalStateContextType>({
  isPurchasingDisabled: false,
  actions: {
    setIsPurchasingDisabled: () => {}
  }
})

export const GlobalStateContextWrapper = (props: React.PropsWithChildren) => {
  const [isPurchasingDisabled, setIsPurchasingDisabled] = useState(false)
  const [actions, setActions] = useState({
    setIsPurchasingDisabled
  })

  return (
    <GlobalStateContext.Provider value={{ isPurchasingDisabled, actions }}>
      {props.children}
    </GlobalStateContext.Provider>
  )
}
