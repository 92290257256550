import { AuthProfileOutput } from '@grandstand/presentation-models/types/responses/profile'
import { useCallback, useContext, useEffect, useState } from 'react'
import { authenticatedApiFetch } from '../apiClient/apiClient'
import { currentUserStorage, getIsDTC, getIsLoggedIn } from '../hooks/useValidUser'
import { Config } from '../services/config/Config'
import { ConfigServiceContext } from '../services/config/ConfigService'
import { ItemStorage } from '../utils/storageUtils'
// Couchrights

export type Couchrights = {
  next_checkin_date: number // Used for limiting checkins from clients
  in_range: boolean // Is the users' current checkin in range
  days_left: number // Number of days before user will be blocked
}

export type CouchrightsWarning = {
  show: boolean
  daysLeft: number
  dismissedAt: number
}

export const couchrightsStorage = new ItemStorage<Couchrights | null>('couchrights.v2', null)
export const couchrightsWarningStorage = new ItemStorage<CouchrightsWarning | null>('couchrightsWarning.v2', null)

export const useCouchrights = (currentUser: AuthProfileOutput | undefined) => {
  const { currentConfig } = useContext(ConfigServiceContext)
  const [couchrights, setCouchrights] = useState<Couchrights | null>(couchrightsStorage.getItem())
  const [couchrightsWarning, setCouchrightsWarning] = useState<CouchrightsWarning | null>(
    couchrightsWarningStorage.getItem()
  )

  // methods
  const dismissCouchrightsWarning = () => {
    const prev = couchrightsWarningStorage.getItem()
    if (prev === null) {
      setCouchrightsWarning(null)
      return
    }
    const next: CouchrightsWarning = {
      ...prev,
      dismissedAt: new Date().getTime()
    }
    couchrightsWarningStorage.setItem(next)
    setCouchrightsWarning(next)
  }
  // checkinCallback. returns cleanup fn
  const checkinCallback = useCallback(() => {
    const debounce = (callback: () => void | Promise<void>, wait?: number) => {
      const ms = wait ?? 250
      const timeout = setTimeout(() => {
        callback()
        clearTimeout(timeout)
      }, wait ?? 250)
      return () => {
        try {
          clearTimeout(timeout)
        } catch (_) {
          // ignore error
        }
      }
    }
    if (currentConfig === undefined || currentUser === undefined) {
      return () => {}
    }

    const updateWarning = (next: Couchrights | null, save?: boolean) => {
      const prev = couchrightsWarningStorage.getItem()
      const handle = (nextWarning: CouchrightsWarning | null) => {
        setCouchrightsWarning(nextWarning)
        if (save) {
          couchrightsWarningStorage.setItem(nextWarning)
        }
      }
      if (prev === null) {
        return handle(null)
      }
      const now = new Date().getTime()
      const dismissedAt = prev?.dismissedAt ?? 0
      const showAt = new Date(dismissedAt).getTime() + 24 * 60 * 60 * 1000
      const show = now + 60 * 60 * 1000 > showAt
      const nextWarning: CouchrightsWarning = {
        daysLeft: next?.days_left ?? 0,
        dismissedAt,
        show
      }
      return handle(nextWarning)
    }
    const update = (next: Couchrights | null, save?: boolean) => {
      setCouchrights(next)
      updateWarning(next, save)
      if (save) {
        couchrightsStorage.setItem(next)
      }
    }

    const isLoggedIn = getIsLoggedIn(currentUser)
    const isDTC = getIsDTC(currentUser)
    if (!isLoggedIn || !isDTC) {
      return debounce(() => {
        // set couchrights to null (state + storage)
        update(null, true)
      })
    }
    return debounce(async () => {
      try {
        const next = await checkin(currentConfig)
        update(next, false)
      } catch (error) {
        console.warn('useCouchrights > checkin > error', { error })
      }
    })
  }, [currentConfig, currentUser])

  // use effect to call checkinCallback()
  useEffect(() => {
    const cancel = checkinCallback()
    return cancel
  }, [checkinCallback])

  return {
    couchrights,
    checkin: checkinCallback,
    couchrightsWarning,
    dismissCouchrightsWarning
  }
}

/**
 * checkin
 * @param currentConfig {Config} - configService.currentConfig (cannot be undefined)
 * @returns Promise<Couchrights | null>
 */
export async function checkin(currentConfig?: Config): Promise<Couchrights | null> {
  const prev = couchrightsStorage.getItem()
  const user = currentUserStorage.getItem()

  if (user?.user_token === undefined || currentConfig === undefined) {
    return null
  }

  // update couchrights StorageItem
  const handle = (next: Couchrights | null): Couchrights | null => {
    if (next) {
      next.days_left = Math.max(next.days_left, 0)
    }
    const shouldReset = next === null || next.days_left <= 0
    if (shouldReset) {
      next = null
    }
    couchrightsStorage.setItem(next)
    return next
  }

  // ...
  const isLoggedIn = getIsLoggedIn(user)
  const isDTC = getIsDTC(user)

  if (!isLoggedIn || !isDTC) {
    return handle(null)
  }

  const getCouchrights = async (): Promise<Couchrights | null> => {
    const res = await authenticatedApiFetch({
      method: 'POST',
      url: currentConfig.API.services.couchrights_services.checkin,
      token: user.user_token
    })
    if (res.ok) {
      const next = (await res.json()) as Couchrights
      return handle(next)
    } else {
      const error = await res.json()
      console.warn('checkin: !res.ok error', { error })
      return handle(null)
    }
  }

  if (prev) {
    const minCheckinTime = new Date().getTime() + 60 * 60 * 1000
    const nextCheckinTime = new Date(prev.next_checkin_date).getTime()
    // if we're within 60 min of nextCheckinTime, return await callCheckin()
    if (minCheckinTime >= nextCheckinTime) {
      return await getCouchrights()
    } else {
      return prev
    }
  }

  return await getCouchrights()
}
