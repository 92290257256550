import { useLocalStorage } from '@grandstand-web/bally-web-shared/src/hooks/useLocalStorage'
import { AnalyticServiceContext } from '@grandstand-web/bally-web-shared/src/services/analytics/AnalyticService'
import moment from 'moment'
import { useRouter } from 'next/router'
import { useContext, useEffect, useRef } from 'react'

const CaptivateAnalytics = () => {
  const analyticService = useContext(AnalyticServiceContext)
  const [captivateKey] = useLocalStorage('captivateKey', '')
  const router = useRouter()
  const analyticsSent = useRef(false)

  useEffect(() => {
    if (analyticsSent.current || !router.isReady) {
      return
    }
    const { key, operation, status, pauseStartDate, pauseEndDate } = router.query
    let shouldRemoveQueryParams = key && operation && status
    const isValidCaptivateKey = key === captivateKey
    if (status === 'completed' && isValidCaptivateKey && operation) {
      if (operation === 'cancel') {
        analyticService.cancelledSubscription()
      }
      if (operation === 'pause' && typeof pauseStartDate === 'string' && typeof pauseEndDate === 'string') {
        const duration = Math.round(
          moment(new Date(parseInt(pauseEndDate))).diff(new Date(parseInt(pauseStartDate)), 'months', true)
        ).toString()
        analyticService.pauseSubscription({ duration })
      }
    }
    if (shouldRemoveQueryParams) {
      analyticsSent.current = true
      router.replace({ pathname: router.asPath.split('?')[0] }, undefined, { shallow: true })
    }
  }, [router, analyticService, captivateKey])
  return null
}
export default CaptivateAnalytics
