import { AuthProfileOutput } from '@grandstand/presentation-models/types/responses/profile'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { getDeviceCategory } from '../../hooks/useDeviceInfo'
import { isNotInProd } from '../../utils/envUtils'
import { Logger } from '../../utils/logger'
import { mapPathnameToPageTitle } from '../analytics/AnalyticService'
import { DeviceRegion, UserServiceContext } from '../user/UserService'
import {
  getDaysBetweenDates,
  getFavoriteTeams,
  getSubRegion,
  getTealiumEntitlement,
  getTeamAccess,
  parseDeviceRegionForTealium,
  paymentMethodForPurchase
} from './tealiumHelpers'
import {
  NewRestoreSubscriptionResponse,
  TealiumCheckoutParams,
  TealiumCreateAccountParams,
  TealiumDeleteAccountParams,
  TealiumFavoriteTeamParams,
  TealiumLoginMVPDParams,
  TealiumLoginParams,
  TealiumPurchaseErrorParams,
  TealiumPurchaseParams,
  TealiumSelectPackageParams,
  TealiumService,
  TealiumUniversalParams,
  TealiumUpdateNameParams,
  TealiumUpdatePaymentMethodParams,
  TealiumUpgradeToAnnualParams,
  TealiumViewPackagesParams,
  TrackParams,
  UtagDataArg
} from './types'

export const TealiumServiceContext = createContext<TealiumService>({} as TealiumService)

type PropsForProvider = PropsWithChildren<{
  app: 'web' | 'connected-web'
}>

const useTealiumServiceProvider = (currentUser?: AuthProfileOutput, deviceRegion?: DeviceRegion) => {
  const universalParams = useMemo<TealiumUniversalParams>(() => {
    const analytics = currentUser?.analytics?.default
    return {
      user_id: analytics?.user_id ?? analytics?.auth_userid,
      user_zip: analytics?.user_zip,
      mvpd_provider: analytics?.mvpd_provider,
      device_category: getDeviceCategory(),
      fav_team: getFavoriteTeams(currentUser),
      sub_region: getSubRegion(analytics),
      entitlement: getTealiumEntitlement(analytics),
      ...parseDeviceRegionForTealium(deviceRegion)
      // hem: // should be hashed + returned by middleware
    }
  }, [currentUser, deviceRegion])

  const tealium = createTealiumService(universalParams)
  return {
    tealium,
    universalParams
  }
}

export const TealiumServiceProvider = ({ children, app }: PropsForProvider) => {
  const userService = useContext(UserServiceContext)
  const { currentUser, deviceRegion } = userService
  const router = useRouter()
  const { tealium, universalParams } = useTealiumServiceProvider(currentUser, deviceRegion)

  // setup debug cookie to show tealium logs in console
  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    if (isNotInProd()) {
      Cookies.set('utagdb', 'true', {
        expires: 365,
        sameSite: 'Strict',
        secure: window.location.protocol.indexOf('https') > -1
      })
    } else {
      Cookies.remove('utagdb', {
        path: '/'
      })
    }
  }, [])

  useEffect(() => {
    const handlePageView = (app: 'web' | 'connected-web', pathname: string) => {
      // do nothing if window.utag.view is not defined
      if (typeof window?.utag?.view === 'undefined') {
        return
      }

      // log pageview
      window.utag.view({
        page_name: mapPathnameToPageTitle(app, pathname.split('?')[0]), // ensure no query string
        ...universalParams
      })
    }

    handlePageView(app, router.asPath)

    const handleRouteChangeComplete = (pathname: string) => {
      handlePageView(app, pathname)
    }
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <TealiumServiceContext.Provider value={tealium}>{children}</TealiumServiceContext.Provider>
}

export const useTealiumFromUserService = (currentUser?: AuthProfileOutput, deviceRegion?: DeviceRegion) => {
  const { tealium } = useTealiumServiceProvider(currentUser, deviceRegion)
  return tealium
}

export const useTealium = () => {
  const tealium = useContext(TealiumServiceContext)
  return tealium
}

export const createTealiumService = (
  universalParams: TealiumUniversalParams,
  currentUser?: AuthProfileOutput
): TealiumService => {
  const isDebug = Cookies.get('utagdb') === 'true'
  const sendAnalytics = <T,>(params: TrackParams<T>): void => {
    if (typeof window?.utag === 'undefined') {
      return
    }
    const data: UtagDataArg<T> = {
      ...universalParams,
      ...params,
      tealium_event: params.event_name
    }
    window.utag.link<T>(data)
  }

  // create TealiumService
  const tealiumService: TealiumService = {
    upsellOpen: () => {
      sendAnalytics({
        event_name: 'subscribe_modal_open'
      })
    },
    upsellClose: () => {
      sendAnalytics({
        event_name: 'subscribe_modal_close'
      })
    },
    upsellStartTrial: () => {
      sendAnalytics({ event_name: 'subscribe_modal-start_trial' })
    },
    upsellSignIn: () => {
      sendAnalytics({ event_name: 'subscribe_modal-sign_in' })
    },
    upsellSignInWithTVProvider: () => {
      sendAnalytics({ event_name: 'subscribe_modal-tv_provider' })
    },
    createAccount: (params) => {
      sendAnalytics<TealiumCreateAccountParams>({
        event_name: 'create_account',
        ...params
      })
    },
    logIn: (params) => {
      sendAnalytics<TealiumLoginParams>({
        event_name: 'login',
        ...params
      })
    },
    logInMVPD: (params) => {
      sendAnalytics<TealiumLoginMVPDParams>({
        event_name: 'mvpd_login',
        ...params
      })
    },
    logOut: () => {
      sendAnalytics({
        event_name: 'log_out'
      })
    },
    changeEmail: () => {
      sendAnalytics({
        event_name: 'change_email'
      })
    },
    deleteAccount: (params) => {
      sendAnalytics<TealiumDeleteAccountParams>({
        event_name: 'delete_account',
        ...params
      })
    },
    updateName: (params) => {
      sendAnalytics<TealiumUpdateNameParams>({
        event_name: 'update_name',
        ...params
      })
    },
    removeFavoriteTeam: (params) => {
      sendAnalytics<TealiumFavoriteTeamParams>({
        event_name: 'remove_team_fav',
        ...params
      })
    },
    addFavoriteTeam: (params) => {
      sendAnalytics<TealiumFavoriteTeamParams>({
        event_name: 'team_fav',
        ...params
      })
    },
    cancelSubscription: () => {
      sendAnalytics({
        event_name: 'cancel_subscription'
      })
    },
    restoreSubscription: (restoredSubscriptionResponse, subscription) => {
      const res = restoredSubscriptionResponse as NewRestoreSubscriptionResponse
      const free_trial_start = res?.free_trial_start ?? subscription?.free_trial_start
      const free_trial_end = res?.free_trial_expiration ?? subscription?.free_trial_expiration
      const params: TealiumPurchaseParams = {
        email: currentUser?.profile?.internal?.email ?? '',
        order_id: res.order_id,
        order_subtotal: res.retail_price,
        order_total: res.purchase_price,
        payment_method: paymentMethodForPurchase(res.payment_method),
        trial_duration: getDaysBetweenDates(free_trial_start, free_trial_end),
        package: subscription.service_name,
        package_code: subscription.service_id,
        package_id: res.service_id,
        package_duration: res.billing_interval,
        currency: 'USD',
        team_access: getTeamAccess(currentUser)
      }
      sendAnalytics<TealiumPurchaseParams>({
        ...params,
        event_name: 'restore_purchase'
      })
    },
    pauseSubscription: (params) => {
      sendAnalytics({
        event_name: 'pause_subscription',
        ...params
      })
    },
    resumeSubscription: () => {
      sendAnalytics({
        event_name: 'resume_subscription'
      })
    },
    updatePaymentMethod: (params) => {
      sendAnalytics<TealiumUpdatePaymentMethodParams>({
        event_name: 'update_payment_method',
        ...params
      })
    },
    viewPackages: (params) => {
      sendAnalytics<TealiumViewPackagesParams>({
        event_name: 'view_packages',
        ...params
      })
    },
    selectPackage: (params) => {
      sendAnalytics<TealiumSelectPackageParams>({
        event_name: 'select_package',
        ...params
      })
    },
    upgradeToAnnual: (params) => {
      sendAnalytics<TealiumUpgradeToAnnualParams>({
        event_name: 'upgrade_to_annual',
        ...params
      })
    },
    checkout: (params) => {
      sendAnalytics<TealiumCheckoutParams>({
        event_name: 'checkout',
        ...params
      })
    },
    purchase: ({ payment_method, new_subscription, user_profile, selectedPkg, isFreeTrial }) => {
      try {
        const free_trial_start = (new_subscription as any).free_trial_start
        const free_trial_expiration = (new_subscription as any).free_trial_expiration
        const params: TealiumPurchaseParams = {
          email: user_profile.profile?.internal?.email ?? '',
          order_id: new_subscription?.order_id ?? '',
          order_subtotal: isFreeTrial ? 0 : selectedPkg.price,
          free_trial_start,
          free_trial_expiration,
          order_total: isFreeTrial ? 0 : new_subscription?.tax_details?.total_price_with_tax ?? 0,
          payment_method: paymentMethodForPurchase(payment_method),
          trial_duration: getDaysBetweenDates(free_trial_start, free_trial_expiration),
          package: selectedPkg.package_name,
          package_code: new_subscription.service_id,
          package_id: new_subscription.payment_id,
          package_duration: new_subscription.billing_interval,
          currency: 'USD',
          team_access: getTeamAccess(user_profile)
        }
        sendAnalytics<TealiumPurchaseParams>({
          event_name: 'purchase_success',
          ...params
        })
      } catch (error) {
        Logger.of('TealiumService.purchase').error('Error sending purchase success event to Tealium', error)
      }
    },
    purchaseError: (params) => {
      sendAnalytics<TealiumPurchaseErrorParams>({
        ...params,
        event_name: 'purchase_error'
      })
    }
  }

  return tealiumService
}
