import { ISO8601 } from '@grandstand/presentation-models'
import { AnalyticsDefault } from '@grandstand/presentation-models/types/analytics/profile'
import { AuthProfileOutput } from '@grandstand/presentation-models/types/responses/profile'
import { DeviceRegion, UserService } from '../user/UserService'
import { TealiumEntitlement, TealiumPaymentMethod } from './types'

// TEMP HELPERS
// these helpers are just here until `AnalyticsTealium` proposal
// is integrated by middleware. View the proposal here:
// https://ballysports.atlassian.net/wiki/spaces/EN/pages/513376279/Analytics+Tealium+Proposal

// converts analytics.subscription_rsn (space-separated if defined) into comma-separeted list of RSN ids
export const getSubRegion = (analytics?: AnalyticsDefault): string | undefined => {
  const subRsn = analytics?.subscription_rsn // if defined, will be a space-separated list of RSN ids ('fst' 'fsw')
  if (subRsn === undefined) {
    return undefined
  }
  return subRsn.split(' ').join(',')
}

type ParsedDeviceRegion = {
  ip_zip: string | undefined
  ip_city: string | undefined
  ip_state: string | undefined
  ip_dma: string | undefined
  zip_rsn_region: string | undefined
}
export const getUserId = (userService: UserService): string | undefined => {
  const analytics = userService.currentUser?.analytics?.default
  if (analytics === undefined) {
    return undefined
  }
  return analytics?.user_id ?? analytics?.auth_userid
}

export const getUserEmail = (userService: UserService): string | undefined => {
  return userService.currentUser?.profile?.internal?.email
}

// parses ip_[zip, city, state, dma] and zip_rsn_region into object (spread into TealiumUniversalParams)
export const parseDeviceRegionForTealium = (deviceRegion?: DeviceRegion): ParsedDeviceRegion => {
  const networks = deviceRegion?.available_networks ?? []
  const parsed: ParsedDeviceRegion = {
    ip_zip: deviceRegion?.zip_code,
    ip_city: deviceRegion?.city,
    ip_state: deviceRegion?.state,
    ip_dma: undefined,
    // comma-separeted list of network ids
    zip_rsn_region: networks.length > 0 ? networks.map((network) => network.id).join(',') : undefined
  }
  return parsed
}

// gets comma-separeted list of favorite teams (if defined) as a string
export const getFavoriteTeams = (currentUser?: AuthProfileOutput): string | undefined => {
  const favTeamIds = currentUser?.profile.favorites.teams ?? []
  const teams = currentUser?.profile?.region.all_regional_teams ?? []
  if (!favTeamIds.length || !teams.length) {
    return undefined
  }
  const teamNames = favTeamIds.reduce((acc: string[], id) => {
    const team = teams.find((team) => team.sport_radar_id === id)
    if (team) {
      return [...acc, team.team]
    }
    return acc
  }, [] as string[])
  return teamNames.join(',')
}

// determine TealiumEntitlement (temp until middleware returns value)
export const getTealiumEntitlement = (analytics?: AnalyticsDefault): TealiumEntitlement => {
  if (analytics === undefined) {
    return 'none'
  }
  const userType = analytics?.user_type // "dtc" | "mvpd" | "mvpd/dtc" | "anonymous" | "free" | "mvpd/free" | undefined
  const subStatuses: string[] =
    analytics?.subscription_status /* string | undefined */
      ?.split('||') ?? []
  const cancelled = subStatuses.length > 0 && subStatuses.every((status) => status.indexOf('cancel') > -1)
  switch (userType) {
    case 'mvpd':
    case 'mvpd/dtc':
    case 'mvpd/free':
      return 'mvpd'
    case 'dtc':
      return cancelled ? 'bally_cancelled' : 'bally_active'
    default:
      return 'none'
  }
}

export const getDaysBetweenDates = (startDate?: ISO8601 | null, endDate?: ISO8601 | null): number => {
  if (!startDate || !endDate) {
    return 0
  }
  const start = new Date(startDate)
  const end = new Date(endDate)
  return Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24))
}

export const paymentMethodForPurchase = (paymentMethod?: string): TealiumPaymentMethod => {
  const method = !paymentMethod ? 'credit' : paymentMethod.toLowerCase()
  return method.indexOf('paypal') > -1 ? 'paypal' : 'credit'
}

export const getTeamAccess = (currentUser?: AuthProfileOutput): string | undefined => {
  if (!currentUser) {
    return undefined
  }
  const regionalTeams = currentUser.profile.region.all_regional_teams
  const teams = regionalTeams.filter((team) => Boolean(team?.DTCavailable)).map((team) => team.team)
  return teams.join('|')
}
