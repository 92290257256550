import jwt from 'jsonwebtoken'
import { parseTemplate } from 'url-template'
import { Logger } from '../utils/logger'

type NonBodyMethods = 'GET' | 'HEAD' | 'OPTIONS'
type BodyMethods = 'PUT' | 'PATCH' | 'DELETE' | 'POST'
type NonBodyRequestOptions = { method: NonBodyMethods }
type BodyRequestOptions = { method: BodyMethods; body?: object }
type AllRequestOptions = { url: string; query?: Record<string, string>; headers?: Record<string, string> }
const isBodyMethod = (
  options: AllRequestOptions & (NonBodyRequestOptions | BodyRequestOptions)
): options is AllRequestOptions & BodyRequestOptions => {
  return ['PUT', 'PATCH', 'DELETE', 'POST'].includes(options.method)
}

/**
 * Performs an asynchronous fetch request to the given URL with the specified method, body and headers.
 * @param {string} options.url - The URL to fetch from.
 * @param {Method} options.method - The HTTP method to use (e.g. GET, POST, PUT, DELETE).
 * @param {string} [options.body] - The optional request body to send along with the request.
 * @param {Record<string,string>} [options.headers] - The optional headers to set for the request.
 * @returns {Promise<Response>} A promise that resolves to a Response object containing the status, headers and body of the response.
 */
export const apiFetch = async (
  options: AllRequestOptions & (NonBodyRequestOptions | BodyRequestOptions)
): Promise<Response> => {
  const headersWithBase: Record<string, string> = {
    ...options.headers,
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }

  return await fetch(options.url, {
    method: options.method,
    headers: headersWithBase,
    body: isBodyMethod(options) ? JSON.stringify(options.body) : undefined
  })
}

type AuthenticatedRequestOptions = { token: string; templated?: boolean }

/**
 * Performs an asynchronous fetch request to the given URL with the specified method, body and headers.
 * @param {string} options.url - The URL to fetch from.
 * @param {Method} options.method - The HTTP method to use (e.g. GET, POST, PUT, DELETE).
 * @param {string} options.token - The JWT token, used in both the 'Authorization' header, as well as for templating links
 * @param {boolean} options.templated - Whether to attempt to template an expand the link, optional, and defaults to 'true'.
 * @param {string} [options.body] - The optional request body to send along with the request.
 * @param {Record<string,string>} [options.headers] - The optional headers to set for the request.
 * @returns {Promise<Response>} A promise that resolves to a Response object containing the status, headers and body of the response.
 */
export const authenticatedApiFetch = async (
  options: AllRequestOptions & (NonBodyRequestOptions | BodyRequestOptions) & AuthenticatedRequestOptions
): Promise<Response> => {
  const parseURL = (url: string): string => {
    if (options.templated ?? true) {
      const template = parseTemplate(url)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return template.expand(jwt.decode(options.token) as Record<string, any>) // hideous
    }
    return url
  }
  try {
    const parsedUrl = parseURL(options.url)
    const headersWithBase: Record<string, string> = {
      ...options.headers,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${options.token}`
    }
    return await fetch(parsedUrl, {
      method: options.method,
      headers: headersWithBase,
      body: isBodyMethod(options) ? JSON.stringify(options.body) : undefined
    })
  } catch (err) {
    Logger.of('apiClient.authenticatedApiFetch').error((err as Error)?.message || 'Error fetching', err)
    throw err
  }
}
