export class ItemStorage<T> {
  key: string
  value: T
  private getInitialValue: () => T

  constructor(key: string, initialValue: T) {
    this.key = key
    this.getInitialValue = (): T => initialValue
    this.value = this.getInitialValue()
    this.getItem()
  }
  private getStorage(): Storage | null {
    if (typeof localStorage !== 'undefined') {
      return localStorage
    }
    if (typeof window !== 'undefined') {
      return window.localStorage
    }
    return null
  }

  getItem(): T {
    // initialilze storage
    const storage = this.getStorage()
    if (!storage) {
      return this.value
    }
    try {
      // Get from local storage by key
      const item = storage.getItem(this.key)
      // Parse stored json or if none return initialValue
      if (item) {
        const value = JSON.parse(item)
        this.value = value as T
        return this.value
      } else {
        this.value = this.getInitialValue()
        try {
          localStorage.setItem(this.key, JSON.stringify(this.value))
        } catch (_) {
          // ignore
        }
        return this.value
      }
    } catch (_) {
      // If error also return initialValue)
      const value = this.getInitialValue()
      this.setItem(value)
      this.value = value
      return this.value
    }
  }
  setItem(value: T | ((val: T) => T)): T {
    const storage = this.getStorage()
    try {
      const valueToStore = value instanceof Function ? value(this.value) : value
      this.value = valueToStore
    } catch (_) {
      // ignore
    }
    if (!storage) {
      return this.value
    }
    try {
      storage.setItem(this.key, JSON.stringify(this.value))
    } catch (_) {
      // ignore
    }
    return this.value
  }
}
